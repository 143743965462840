import Step1Component from './Step1/Step1'
import Step2Component from './Step2/Step2'
import Step3Component from './Step3/Step3'
import Step4Component from './Step4/Step4'
import Step5Component from './Step5/Step5'
import EditComponent from './Edit/Edit'

export const Step1 = Step1Component
export const Step2 = Step2Component
export const Step3 = Step3Component
export const Step4 = Step4Component
export const Step5 = Step5Component
export const Edit = EditComponent
