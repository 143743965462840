import getMolinos from './getMolinos'
import getMolino from './getMolino'
import saveMolino from './saveMolino'
import getTiposEquipo from './getTiposEquipo'
import getTiposPieza from './getTiposPieza'
import getPersonas from './getPersonas'
import uploadConfigTiposEquipo from './uploadConfigTiposEquipo'
import uploadConfigTiposPieza from './uploadConfigTiposPieza'
import uploadConfigPersonal from './uploadConfigPersonal'
import uploadSchedule from './uploadSchedule'
import updatePartTask from './updatePartTask'
import updateTask from './updateTask'
import updateStage from './updateStage'
import updateTurnoHistorial from './updateTurnoHistorial'
import updateEvento from './updateEvento'
import getActivityByMolino from './getActivityByMolino'
import deleteActivity from './deleteActivity'

export const getMolinosPromise = getMolinos
export const getMolinoPromise = getMolino
export const saveMolinoPromise = saveMolino
export const getTiposEquipoPromise = getTiposEquipo
export const getTiposPiezaPromise = getTiposPieza
export const getPersonasPromise = getPersonas
export const uploadConfigTiposEquipoPromise = uploadConfigTiposEquipo
export const uploadConfigTiposPiezaPromise = uploadConfigTiposPieza
export const uploadConfigPersonalPromise = uploadConfigPersonal
export const uploadSchedulePromise = uploadSchedule
export const updatePartTaskPromise = updatePartTask
export const updateTaskPromise = updateTask
export const updateStagePromise = updateStage
export const updateTurnoHistorialPromise = updateTurnoHistorial
export const updateEventoPromise = updateEvento
export const getActivityByMolinoPromise = getActivityByMolino
export const deleteActivityPromise = deleteActivity
