import Maintenance from './Maintenance/Maintenance'
import AdminAudit from './AdminAudit/AdminAudit'
import AdminUsers from './AdminUsers/AdminUsers'
import Home from './Home/Home'
import Login from './Login/Login'
import NotAuthorized from './NotAuthorized/NotAuthorized'
import NotFound from './NotFound/NotFound'

export const MaintenancePage = Maintenance
export const AdminAuditPage = AdminAudit
export const AdminUsersPage = AdminUsers
export const HomePage = Home
export const LoginPage = Login
export const NotAuthorizedPage = NotAuthorized
export const NotFoundPage = NotFound
